import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <iframe title={"title"} id={"introduce-frame"} src={"https://my.surfit.io/w/74536062"} frameBorder={"0"} width={"100%"} />
    </div>
  );
}

export default App;
